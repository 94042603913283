import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=283780f1&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Header.vue?vue&type=style&index=1&id=283780f1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "283780f1",
  null
  
)

/* custom blocks */
import block0 from "./Header.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalsTemplateModal: require('/var/www/ggk_geoportal/ui/modules/ggk_module/components/Modals/TemplateModal.vue').default})
